import { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Spin } from "antd";

const AppContainer = lazy(() => import('./AppContainer'));

export function RouterContainer() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Spin />}>
        <AppContainer />
      </Suspense>
    </BrowserRouter>
  );
}